export const superviseColumns = () => {
  return [
    {
      title: '序号',
      width: '80px',
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '标题',
      align: 'left',
      dataIndex: 'title'
    },
    {
      title: '发布人',
      align: 'center',
      width: '120px',
      dataIndex: 'publisher.name'
    },
    {
      title: '发布时间',
      align: 'center',
      width: '180px',
      dataIndex: 'publishTime'
    },
    {
      title: '接收类型',
      align: 'center',
      width: '100px',
      dataIndex: 'receiveType',
      scopedSlots: { customRender: 'receiveType' }
    },
    {
      title: '接收对象',
      align: 'left',
      minWidth: '240px',
      dataIndex: 'receiveNames'
    },
    {
      title: '是否已读',
      align: 'center',
      width: '100px',
      scopedSlots: { customRender: 'readNum' }
    },
    {
      title: '操作',
      align: 'center',
      width: '160px',
      scopedSlots: { customRender: 'action' }
    }
  ]
}

export const superviseStateColumns = () => {
  return [
    {
      title: '序号',
      width: '80px',
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '标题',
      align: 'left',
      dataIndex: 'supervise.title'
    },
    {
      title: '发布人',
      align: 'center',
      width: '120px',
      dataIndex: 'supervise.publisher.name'
    },
    {
      title: '发布时间',
      align: 'center',
      width: '180px',
      dataIndex: 'supervise.publishTime'
    },
    {
      title: '接收类型',
      align: 'center',
      width: '100px',
      dataIndex: 'receiveType',
      scopedSlots: { customRender: 'receiveType' }
    },
    {
      title: '是否已读',
      align: 'center',
      width: '100px',
      dataIndex: 'ifRead',
      scopedSlots: { customRender: 'ifRead' }
    },
    {
      title: '操作',
      align: 'center',
      width: '100px',
      scopedSlots: { customRender: 'action' }
    }
  ]
}

//是否需要回复
export const ifReply = function () {
  return [
    {
      value: 'true',
      label: '是'
    },
    {
      value: 'false',
      label: '否'
    }
  ]
}

//接收类型
export const receiveTypeData = function () {
  return [
    {
      value: 'all',
      label: '全部'
    },
    {
      value: 'dept',
      label: '部门'
    },
    {
      value: 'user',
      label: '用户'
    }
  ]
}

//接收类型
export const systemModuleData = function () {
  return [
    {
      value: 'notice',
      label: '通知'
    },
    {
      value: 'inspection_bill_item',
      label: '巡检'
    },
    {
      value: 'dpc_other_education',
      label: '教育培训'
    },
    {
      value: 'dpc_other_safe_activity',
      label: '安全活动'
    },
    {
      value: 'supervise',
      label: '督办'
    }
  ]
}